// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function AllerLogo(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "text-primary";
  return JsxRuntime.jsxs("svg", {
              children: [
                JsxRuntime.jsx("g", {
                      children: JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M758.661 0.75C758.41 1.163 588.471 404.25 381.019 896.5C173.567 1388.75 2.99633 1793.41 1.97233 1795.75L0.111328 1800L257.267 1799.75L514.423 1799.5L610.537 1579.17C663.4 1457.99 707.068 1358.92 707.576 1359C708.084 1359.09 802.017 1458.24 916.316 1579.33C1030.61 1700.42 1124.55 1799.5 1125.07 1799.5C1125.59 1799.5 1219.67 1700.28 1334.15 1579C1448.62 1457.72 1542.52 1358.5 1542.82 1358.5C1543.11 1358.5 1586.63 1457.72 1639.53 1579L1735.72 1799.5L1992.81 1799.75L2249.9 1800L2248.34 1796.25C2235.61 1765.74 1492.52 2.998 1491.86 1.75C1490.98 0.0880001 1472.49 0 1125.02 0C923.776 0 758.913 0.337 758.661 0.75ZM1256.77 674.75C1328.88 826.963 1388.02 951.966 1388.19 952.536C1388.53 953.687 1126.38 1267 1125.07 1267C1124.62 1267 1065.18 1196.49 992.977 1110.32C869.298 962.7 861.757 953.482 862.679 951.04C864.8 945.42 1124.53 398 1125.07 398C1125.39 398 1184.66 522.537 1256.77 674.75Z",
                            fill: "currentColor",
                            fillRule: "evenodd"
                          }),
                      clipPath: "url(#clip0_996_13291)"
                    }),
                JsxRuntime.jsx("defs", {
                      children: JsxRuntime.jsx("clipPath", {
                            children: JsxRuntime.jsx("rect", {
                                  height: "1801",
                                  width: "2250",
                                  fill: "white"
                                }),
                            id: "clip0_996_13291"
                          })
                    })
              ],
              className: className,
              height: "1801",
              width: "2250",
              fill: "none",
              viewBox: "0 0 2250 1801",
              xmlns: "http://www.w3.org/2000/svg"
            });
}

var make = AllerLogo;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
