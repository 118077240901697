// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RadarPageQuery_graphql from "../__generated__/RadarPageQuery_graphql.mjs";

function serialize(t) {
  return t.type + ":" + t.order;
}

function parse(s) {
  var match = s.split(":");
  if (match.length !== 2) {
    return ;
  }
  var type_ = match[0];
  var order = match[1];
  var order$1 = RadarPageQuery_graphql.Utils.sortOrder_fromString(order);
  var type_$1 = RadarPageQuery_graphql.Utils.sortType_fromString(type_);
  if (type_$1 !== undefined && order$1 !== undefined) {
    return {
            order: order$1,
            type: type_$1
          };
  }
  
}

function make(type_Opt, orderOpt, param) {
  var type_ = type_Opt !== undefined ? type_Opt : "publishedAt";
  var order = orderOpt !== undefined ? orderOpt : "DESCENDING";
  return {
          order: order,
          type: type_
        };
}

var $$default = {
  order: "DESCENDING",
  type: "publishedAt"
};

export {
  serialize ,
  parse ,
  $$default as default,
  make ,
}
/* RadarPageQuery_graphql Not a pure module */
